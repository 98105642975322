/* ClientLogos.jsx */
import React from 'react';
import './ClientLogos.css';
import logo1 from '../../asset/client/NorthCarolina_small.jpg';
import logo2 from '../../asset/client/SouthCarolina_small.jpg';
import logo3 from '../../asset/client/access_small.jpg';
import logo4 from '../../asset/client/aflac_small.jpg';
import logo5 from '../../asset/client/boa_small.jpg';
import logo6 from '../../asset/client/cognizant_logo.jpg';
import logo7 from '../../asset/client/comsys.jpg';
import logo8 from '../../asset/client/geo_small.jpg';
import logo9 from '../../asset/client/ibm_small.jpg';
import logo10 from '../../asset/client/ibmpartner.jpg';
import logo11 from '../../asset/client/kforce.jpg';
import logo12 from '../../asset/client/mspartner.jpg';
import logo13 from '../../asset/client/shi.jpg';
import logo14 from '../../asset/client/site_small.jpg';
import logo15 from '../../asset/client/tek.jpg';
import logo16 from '../../asset/client/ten_small.jpg';

const ClientLogos = () => {
  return (
    <div className="client-logos-container">
      <h2>Our Clients</h2>
      <div className="client-logos">
        <img src={logo1} alt="Client 1" />
        <img src={logo2} alt="Client 2" />
        <img src={logo3} alt="Client 3" />
        <img src={logo4} alt="Client 4" />
        <img src={logo5} alt="Client 5" />
        <img src={logo6} alt="Client 6" />
        <img src={logo7} alt="Client 6" />
        <img src={logo8} alt="Client 6" />
        <img src={logo9} alt="Client 6" />
        <img src={logo10} alt="Client 6" />
        <img src={logo11} alt="Client 6" />
        <img src={logo12} alt="Client 6" />
        <img src={logo13} alt="Client 6" />
        <img src={logo14} alt="Client 6" />
        <img src={logo15} alt="Client 6" />
        <img src={logo16} alt="Client 6" />
      </div>
    </div>
  );
};

export default ClientLogos;

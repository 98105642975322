import "./FoodBeverage.css";
import { useNavigate } from "react-router-dom";

const FoodAndBeverageComponent = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/think-nova");
    window.scrollTo(0, 0);
  };

  return (
    <div className="nova-container">
      <h2 className="nova-title">Transforming Food & Beverage with AI Innovation</h2>
      <p className="nova-text">
        Elevating Dining Experiences: Smarter, Greener, and More Personalized
      </p>

      <p className="nova-text">
        Artificial Intelligence is reshaping the food and beverage landscape, introducing cutting-edge solutions that enhance efficiency, sustainability, and customer engagement. From intelligent kiosks to data-driven insights, AI is empowering businesses to deliver exceptional dining experiences while reducing operational costs.
      </p>

      <h3 className="nova-title">Why AI is the Future of Food & Beverage</h3>
      <ul className="nova-list">
        <li>Seamless Ordering: AI-powered systems enable quick and error-free transactions, enhancing customer satisfaction.</li>
        <li>Personalized Menus: Tailored recommendations based on dietary preferences, allergies, and past orders.</li>
        <li>Sustainability Focus: AI optimizes inventory management, reducing food waste and promoting eco-friendly practices.</li>
        <li>Enhanced Customer Insights: Analyze purchasing patterns to refine marketing strategies and menu offerings.</li>
        <li>Global Reach: Multilingual support and cultural customization for diverse customer bases.</li>
      </ul>

      <h3 className="nova-title">AI in Action: Real-World Applications</h3>
      <p className="nova-text">
        Discover how AI is revolutionizing the industry:
      </p>
      <ul className="nova-list">
        <li>Smart Kitchens: AI monitors food preparation, ensuring consistency and quality.</li>
        <li>Dynamic Pricing: Adjust menu prices in real-time based on demand and inventory levels.</li>
        <li>Voice-Activated Ordering: Hands-free, voice-enabled kiosks for a futuristic dining experience.</li>
        <li>Predictive Analytics: Forecast trends and customer preferences to stay ahead of the competition.</li>
        <li>Contactless Solutions: AI-driven touchless systems for a safer, hygienic experience.</li>
      </ul>

      <h3 className="nova-title">Building a Sustainable Future</h3>
      <p className="nova-text">
        AI is not just about convenience—it's about creating a sustainable future for the food and beverage industry. By minimizing waste, optimizing resources, and enhancing operational efficiency, businesses can reduce their environmental footprint while maximizing profitability.
      </p>
      <p className="nova-text">
        Join the movement towards a smarter, greener dining experience. Partner with us to integrate AI solutions that align with your business goals and values.
      </p>
      
      <button className="nova-button" onClick={handleNavigation}>Explore AI Solutions</button>
    </div>
  );
};

export default FoodAndBeverageComponent;
import React from "react";
import "./ApplicationsManagement.css";

const ApplicationsManagement = () => {
  return (
    <div className="management-container">
      <h2 className="management-title">Applications Management</h2>
      <p className="management-text">
        With the emergence of e-business technologies, even small and medium-scale corporations encounter a scarcity of IT resources for system development and maintenance. Irrespective of size, companies must innovate and accelerate the rollout of new systems and technologies without sacrificing application maintenance and enhancement.
      </p>

      <p className="management-text">
        Our Application Management Solution (AMS) helps organizations turn challenges into benefits. We assist clients in addressing cost, complexity, and capacity issues in all stages of the application life cycle, including development, enhancement, maintenance, and support.
      </p>

      <h3 className="management-subtitle">Key Benefits</h3>

      <div className="benefits-grid">
        <div className="benefit-card">
          💰 <strong className="strong">Cost Savings</strong>
          <p>Our Global Delivery Model is highly cost-effective, ensuring high ROI.</p>
        </div>
        <div className="benefit-card">
          🎯 <strong className="strong">Responsibility</strong>
          <p>We take full ownership of enterprise applications, freeing up your internal teams.</p>
        </div>
        <div className="benefit-card">
          🏆 <strong className="strong">Guaranteed Service Levels</strong>
          <p>Our world-class methodology ensures service reliability and excellence.</p>
        </div>
      </div>

      <h3 className="management-subtitle">Technology Expertise</h3>
      <p className="management-text">
        Our expertise spans multiple platforms, databases, languages, and software. We provide support for enterprise applications such as:
      </p>

      <div className="tech-list">
        <span>📌 SAP</span>
        <span>📌 JDE</span>
        <span>📌 Peoplesoft</span>
        <span>📌 GEAC</span>
        <span>📌 Manugistics</span>
      </div>
    </div>
  );
};

export default ApplicationsManagement;

// Sitemap.js
import React from "react";
import { Link } from "react-router-dom";
import { FaHome, FaUsers, FaCogs, FaIndustry, FaRobot, FaHandHoldingHeart, FaBriefcase, FaPhone } from "react-icons/fa";
import './siteMap.css';

const Sitemap = () => {
  return (
    <div className="start">
      <div className="sitemap-container">
      <div className="quick-links-section">
        <b>Quick Links</b>
        <Link to="/" className="new-footer-link">
          <FaHome style={{ marginRight: '8px' }} /> Home
        </Link>
        <Link to="/company-overview" className="new-footer-link">
          <FaUsers style={{ marginRight: '8px' }} /> Company Overview
        </Link>
        <Link to="/history" className="new-footer-link">
          <FaUsers style={{ marginRight: '8px' }} /> Leadership Team
        </Link>
        <Link to="/how-we-work" className="new-footer-link">
          <FaCogs style={{ marginRight: '8px' }} /> How We Work
        </Link>
      </div>
      <div className="services-section">
        <b>Services</b>
        <Link to="/application-development" className="new-footer-link">
          <FaIndustry style={{ marginRight: '8px' }} /> Application Development
        </Link>
        <Link to="/government" className="new-footer-link">
          <FaHandHoldingHeart style={{ marginRight: '8px' }} /> Government
        </Link>
        <Link to="/FinancialSector" className="new-footer-link">
          <FaBriefcase style={{ marginRight: '8px' }} /> Financial Sector
        </Link>
        <Link to="/FoodBeverage" className="new-footer-link">
          <FaIndustry style={{ marginRight: '8px' }} /> Food & Beverage
        </Link>
      </div>
      <div className="products-section">
        <b>Products</b>
        <Link to="/think-nova" className="new-footer-link">
          <FaRobot style={{ marginRight: '8px' }} /> Think Nova
        </Link>
      </div>
      <div className="technologies-section">
        <b>Technologies</b>
        <Link to="/artificial-intelligence" className="new-footer-link">
          <FaRobot style={{ marginRight: '8px' }} /> Artificial Intelligence
        </Link>
        <Link to="/MachineLearning" className="new-footer-link">
          <FaRobot style={{ marginRight: '8px' }} /> Machine Learning
        </Link>
       
      </div>
      <div className="other-section">
        <b>Other</b>
        <Link to="/ItDemand" className="new-footer-link">
          <FaHandHoldingHeart style={{ marginRight: '8px' }} /> IT On Demand
        </Link>
        <Link to="/Contact-us" className="new-footer-link">
          <FaPhone style={{ marginRight: '8px' }} /> Contact Us
        </Link>
        <Link to="/Career" className="new-footer-link">
          <FaBriefcase style={{ marginRight: '8px' }} /> Career
        </Link>
        <Link to="/site-map"  className="new-footer-link">
          <FaRobot style={{ marginRight: '8px' }} /> Sitemap
        </Link>
      </div>
    </div>
    </div>
  );
};

export default Sitemap;
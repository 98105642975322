import React from "react";
import { FaCloud, FaTools, FaUsers, FaChartLine, FaCogs, FaDatabase, FaCode, FaProjectDiagram, FaServer, FaLightbulb } from "react-icons/fa";
import "../Application/Dashboard.css";

const services = [
  {
    title: "ML Framework",
    description: "Deploy ML Applications at scale with a powerful Cloud Framework.",
    icon: <FaCloud />,
  },
  {
    title: "Tailored Solutions",
    description: "Custom ML Tools & Pipelines for enterprise needs.",
    icon: <FaTools />,
  },
  {
    title: "CIO Advisory",
    description: "ML Roadmap for Leaders & Technical Teams.",
    icon: <FaChartLine />,
  },
  {
    title: "Workforce Solutions",
    description: "Skill Development & ML Staffing for enterprises.",
    icon: <FaUsers />,
  },
];

const enterpriseNeeds = [
  {
    category: "Operations",
    details: ["Quality Improvement", "Predictive Maintenance"],
    icon: <FaCogs />,
  },
  {
    category: "Marketing",
    details: ["Customer Insights", "Market Behavior"],
    icon: <FaChartLine />,
  },
  {
    category: "Customer Service",
    details: ["Customer Behavior Analysis"],
    icon: <FaUsers />,
  },
  {
    category: "Sales & Finance",
    details: ["Budget Forecasting", "Risk Prediction"],
    icon: <FaDatabase />,
  },
];

const technologyRoadmap = [
  {
    title: "Analyze Your Application",
    description: "Check your current infrastructure capability.",
    icon: <FaProjectDiagram />,
  },
  {
    title: "Provide Consultation",
    description: "Offer solutions to achieve ML capabilities.",
    icon: <FaLightbulb />,
  },
  {
    title: "Implement Solutions",
    description: "Provide frameworks and navigate to production faster.",
    icon: <FaServer />,
  },
  {
    title: "Monitoring & Controlling",
    description: "Ensure smooth operations and long-term success.",
    icon: <FaCode />,
  },
];

const approach = [
  {
    title: "Leadership",
    description: "Understand Technical Leader’s Vision & Mission.",
  },
  {
    title: "Technical Teams",
    description: "Understand applications & pipelines.",
  },
  {
    title: "Solve Problem",
    description: "Add Framework & Tailor ML Tools & Pipelines.",
  },
  {
    title: "Working Solution",
    description: "Enterprise-Ready Implementation.",
  },
];

const MachineLearning = () => {
  return (
    <div className="ml-container">
      <h2 className="ml-title">Featured Services</h2>
      <p className="ml-subtitle">Empowering your enterprise to implement AI Faster</p>

      {/* Service Cards */}
      <div className="ml-services">
        {services.map((service, index) => (
          <div key={index} className="ml-card">
            <div className="ml-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>

      {/* Enterprise Needs */}
      <h2 className="ml-section-title mt-4">Across Organization Needs</h2>
      <p className="ml-description">Machine Learning plays a crucial role across enterprise applications.</p>

      <div className="ml-enterprise">
        {enterpriseNeeds.map((need, index) => (
          <div key={index} className="ml-enterprise-card">
            <div className="ml-icon">{need.icon}</div>
            <h3>{need.category}</h3>
            <ul>
              {need.details.map((detail, i) => (
                <li key={i}>{detail}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Technology Roadmap */}
      <h2 className="ml-section-title mt-4">Technology Roadmap</h2>
      <p className="ml-description">We add a “Machine Learning Layer” to your existing Applications & Data.</p>

      <div className="ml-roadmap">
        {technologyRoadmap.map((step, index) => (
          <div key={index} className="ml-step">
            <div className="ml-icon">{step.icon}</div>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>

      {/* Approach */}
      <h2 className="ml-section-title mt-4">How We Approach</h2>
      <div className="ml-approach">
        {approach.map((step, index) => (
          <div key={index} className="ml-approach-card">
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>

      {/* Contact Section */}
      <div className="ml-contact">
        <h2>Monitoring & Controlling</h2>
        <p>Contact us for more information and service evaluation.</p>
      </div>
    </div>
  );
};

export default MachineLearning;

import React, { useState } from "react";
import { FaSearch, FaLightbulb, FaRocket } from "react-icons/fa";
import "../Application/Dashboard.css";

const overviewData = [
  {
    title: "ASSESS",
    description: [
      "First, we’ll assess your competencies in your enterprise applications and infrastructure.",
      "From a full product assessment to AI goals that align with your mission & vision."
    ],
    icon: <FaSearch />,
  },
  {
    title: "ADVISE",
    description: [
      "We’ll advise on how to transition to AI-Machine Learning (ML).",
      "We provide tactical recommendations that transform into ML solutions from Inception to Scalable Implementation."
    ],
    icon: <FaLightbulb />,
  },
  {
    title: "ACTIVATE",
    description: [
      "We enable your team to create, develop, and deploy Pilot programs.",
      "Identifying gaps to scale to high-capacity Production Applications, including workforce fulfillment."
    ],
    icon: <FaRocket />,
  },
];

const Overview = () => {
  const [selected, setSelected] = useState(0);

  return (
    <div>
      <div className="overview-container">
      <h2 className="overview-title">HOW WE HELP</h2>
      <p className="overview-subtitle">WE OFFER ARTIFICIAL INTELLIGENCE SOLUTIONS</p>

      <div className="overview-content">
        {/* Sidebar Navigation */}
        <div className="overview-sidebar">
          {overviewData.map((item, index) => (
            <button
              key={index}
              className={`overview-button ${selected === index ? "active" : ""}`}
              onClick={() => setSelected(index)}
            >
              <span className="icon">{item.icon}</span>
              {item.title}
            </button>
          ))}
        </div>

        {/* Content Display */}
        <div className="overview-description">
          <div className="icon-large">{overviewData[selected].icon}</div>
          <h3>{overviewData[selected].title}</h3>
          {overviewData[selected].description.map((line, index) => (
            <p key={index} className="description-line">{line}</p>
          ))}
          <a href="/MachineLearning" className="learn-more">
            Click here for Machine Learning →
          </a>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Overview;

import "./Global.css";
import studioImage from "../../asset/think_one.jpg";
import vfxImage from "../../asset/think_two.jpg";
import vfx from "../../asset/think_three.jpg";
import Image from "../../asset/think_four.jpg";

const GlobalOffshoreCapabilities = () => {
  const benefits = [
    "Cost savings from 40% to 70%",
    "Access to highly skilled professionals",
    "Industry-specific expertise",
    "Optimized business processes",
    "Focus on core business operations",
  ];

  const industries = ["GE", "Lucent", "Oracle", "Microsoft", "Motorola"];

  const performanceAreas = [
    "Strategy",
    "Customer Relationship Management",
    "Finance and Performance Management",
    "People Performance",
    "Supply Chain Management",
    "Security",
  ];

  const mocapServices = [
    "Project Consulting – Planning & Scheduling",
    "Single and Multiple Capture",
    "Performance Capture",
    "Prop Capture",
  ];

  const vfxServices = [
    "Compositing",
    "Roto-scoping",
    "Rig Removal",
    "Re-targeting",
    "Pipeline Integration",
    "Sound Stage",
    "3D Animation Production Support",
  ];


  const animationProductionProcess = [
    "Character, sets and prop design",
    "Storyboarding",
    "Matte painting",
    "Set modeling",
    "Character modeling",
    "Texturing",
    "Rigging",
    "Animation",
    "Lighting",
    "3D Effects",
    "Dynamics",
    "Special Effects",
    "Compositing",
  ];

  return (
    <div className="content-box">
      <h1 className="title">Global Offshore Development Capabilities</h1>
      <p className="description">
        A significant factor in the strength and USP of TDS is its sizeable offshore capability
        of highly skilled professionals, assessed at SEI CMMi Level 5. Our global offshore
        capabilities create evident competitive advantages. Cost savings of this model can
        vary from 40% to 70%. At TDS, you will work with domain experts who understand
        your unique challenges, who know exactly how to be in your shoes, and who can
        help set you apart in your industry.
      </p>
      <p className="description">
        This industry focus—with an understanding of key business issues and trends, and
        applicable technologies and business solutions—enables us to deliver innovative
        solutions tailored to each client or, as appropriate, standardized capabilities to
        multiple clients. To complement our industry teams and respond to specific performance
        needs, we offer consulting experience and practical skills in the business processes
        and functions that are critical to helping you achieve high performance.
      </p>
      <p className="description">
        Our Global offshore capabilities create measurable competitive advantage by leveraging
        the synergies among related business processes. Companies can realize savings in cycle
        times and costs, enabling them to compete much more aggressively on price and performance.
        They can also gain competitive advantage by focusing more investment and management time
        on their core businesses.
      </p>
      <p className="description">
        40% of the Fortune 500 companies including GE, Lucent, Oracle, Microsoft, and Motorola are
        leveraging the outsourcing model. The list is growing depending upon the onsite and the
        outsource resources available. Offshore Software Outsourcing is the most leading area of
        outsourcing. Cost saving by adopting this model can vary from 40% to 70%.
      </p>
      <h2 className="sub-title">Key Benefits:</h2>
      <ul className="benefits-list" >
        {benefits.map((benefit, index) => (
          <li key={index} className="benefit-item">{benefit}</li>
        ))}
      </ul>
      <h2 className="sub-title">Trusted by Fortune 500 Companies:</h2>
      <div className="company-list">
        {industries.map((company, index) => (
          <span key={index} className="company-item">{company}</span>
        ))}
      </div>
      <h2 className="sub-title">Performance Areas:</h2>
      <ul className="performance-list">
        {performanceAreas.map((area, index) => (
          <li key={index} className="performance-item">{area}</li>
        ))}
      </ul>

      {/* Think Animation Studio Section */}
      <h1 className="title">Think Animation Studio</h1>
      <p className="description">
        We specialize in motion capture services for Game Development and 3D Animation for the Film and Television industry. Our team consists of producers, directors, artists, and technicians offering a full range of custom MOCAP services. We provide expertise in:
      </p>
      <h2 className="sub-title">MOCAP Services:</h2>
      <ul className="services-list" >
        {mocapServices.map((service, index) => (
          <li key={index} className="service-item">{service}</li>
        ))}
      </ul>
      <div className="studio-image">
        <img src={studioImage} alt="Think Animation Studio" className="studio-image-img" />
      </div>

      {/* Motion Editing and VFX Services Section */}
      <h1 className="title">Motion Editing & VFX Services</h1>
      <p className="description">
        Our VFX services provide a wide range of capabilities to enhance your production.
        From compositing to 3D animation, we have the expertise to support your visual effects
        needs. Here's a look at some of our key services in Motion Editing and VFX:
      </p>
      <h2 className="sub-title">VFX Services:</h2>
      <ul className="services-list">
        {vfxServices.map((service, index) => (
          <li key={index} className="service-item">{service}</li>
        ))}
      </ul>
      <div className="studio-image">
        <img src={vfxImage} alt="VFX Services" className="studio-image-img" />
      </div>

      <h1 className="title">D Animation Production Process</h1>
      <p className="description">
        Our D Animation production process includes all the essential stages to create high-quality animation for your projects. Here's a breakdown of the key steps involved:
      </p>
      <ul className="services-list">
        {animationProductionProcess.map((step, index) => (
          <li key={index} className="service-item">{step}</li>
        ))}
      </ul>
      <div className="studio-image">
        <img src={vfx} alt="VFX Services" className="studio-image-img" />
      </div>

      <h1 className="title">Our VFX services provide the following value-added services:</h1>
      <ul className="services-list">
        <li className="service-item">Compositing</li>
        <li className="service-item">Roto-scoping</li>
        <li className="service-item">Rig Removal</li>
      </ul>
      <div className="studio-image">
        <img src={Image} alt="VFX Services" className="studio-image-img" />
      </div>

    </div>
  );
};

export default GlobalOffshoreCapabilities;

import React from 'react';
import './SOAArchitecture.css';
import soaImage from '../../asset/jboss2_img.jpg';

const SOAArchitecture = () => {
  return (
    <div className="soa-container">
      <h1 className="soa-title">SOA Architecture</h1>
      <img src={soaImage} alt="SOA Architecture" className="soa-image" />

      <p className="soa-text">
        Service Oriented Architecture is a foundation that bridges the gap between Business and Information Technology through well-defined business-oriented services, established technical design principles, proven frameworks, clear patterns, and methods.
      </p>

      <h2 className="soa-subtitle">JBoss Enterprise SOA Platform</h2>
      <p className="soa-text">
        TDS, as JBoss Ready Partner, provides an SOA solution based on JBoss Enterprise SOA Platform. It enables superior business execution, responsiveness, and flexibility in a cost-effective, open platform. JBoss Enterprise SOA Platform allows IT to leverage existing (MoM and EAI), modern (SOA and BPM-Rules), and future (EDA and CEP) integration methodologies to dramatically improve business process execution speed and quality.
      </p>

      <h2 className="soa-subtitle">Value / Benefits</h2>
      <ul className="soa-benefits">
        <li>Decrease development complexity</li>
        <li>Improve inadequate end-user experience</li>
        <li>Resolve business process friction</li>
        <li>Enable unparalleled flexibility</li>
        <li>Significantly lower total cost of ownership (TCO)</li>
        <li>Delivers superior value</li>
      </ul>

      <h2 className="soa-subtitle">JBoss Enterprise Frameworks</h2>
      <p className="soa-text">
        JBoss Enterprise Frameworks are modular frameworks that run with any Java EE middleware platform. They simplify development and speed up deployment.
      </p>
      <ul className="soa-frameworks">
        <li><strong className='strong'>JBoss Hibernate:</strong> Relational database persistence for Java and SOA applications</li>
        <li><strong className='strong'>JBoss Seam:</strong> Next-generation Web 2.0 enterprise applications</li>
        <li><strong className='strong'>JBoss jBPM:</strong> Business processes that coordinate people, applications, and services</li>
        <li><strong className='strong'>JBoss Rules:</strong> Easy business rules policy access and management</li>
      </ul>
    </div>
  );
};

export default SOAArchitecture;

import React from "react";
import "./Team.css";
import teamImage from "../../asset/leadership.png"; // Import your team image

const teamMembers = [
  { name: "P. I. Joy", position: "President" },
  { name: "Nancy J", position: "CEO" },
  { name: "Joseph J", position: "CFO" },
  { name: "James Joy", position: "COO" },
  { name: "Joanna Alexander", position: "CAO" },
  { name: "Bobby Thomas", position: "Director - Accounting & Finance" },
  { name: "Raju T", position: "Director - Recruitment & Placement" },
  { name: "Sammy", position: "VP - Sales & Marketing" },
  {name : "Rony Koshy " , position:"  Manager - Resources"}
];


const Team = () => {
  return (
    <div className="team-container">
      <img src={teamImage} alt="Team" className="team-banner" />
      <h2>Our Team</h2>
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div className="team-member" key={index}>
            <h3>{member.name}</h3>
            <p>{member.position}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;

import React, { useState } from 'react';
import './StaffingServices.css'; // You will create this updated CSS file

const StaffingServices = () => {
    const [activeSection, setActiveSection] = useState('overview');

    const sections = {
        overview: (
            <div className="staffing-services-content">
                <h1 className="staffing-services-title">Staffing Services</h1>
                <p className="staffing-services-description">
                    TDS believes that greater achievements are accomplished by relationships rather than transactions. We work to build a relationship with you that makes it possible for us to do whatever it takes to provide you with the best possible service. This approach to customer satisfaction makes us the professional staffing organization of choice for our customers.
                    TDS focuses on understanding each customer's unique needs and meeting those needs.<br/> <br/>TDS utilizes every resource, process, and system to consistently exceed our customer's expectations. Our strength is our commitment to earn your trust every day.
                    A relationship built on trust is a relationship that will last.
                    TDS believes the entire system has to be quality driven. Quality is a continuous process. It is how we run our company. We provide seamless execution and delivery of our services. Our team is top rate, it has been given the right training, provided a nurturing and creative environment and is managed in an objective, metric based system. Our Quality process is based on our holistic approach that starts at the top and ends with great service.
                    We partner with our customers to provide mutual benefitsWe provide the highest quality resources available in the market placeWe treat our employees as customers, thus leading to satisfied employees who perform at the highest level
                </p>
            </div>
        ),
        contractServices: (
            <div className="staffing-services-content">
                <h1 className="staffing-services-title">Contract Services</h1>
                <p className="staffing-services-description">
                    Contract Services are a perfect solution for companies that experience frequent fluctuation in their need for technical resources. Think Development Systems is there to provide the required technical resources to complete the job, as well as to handle discontinuation of existing contracts. Think Development handles all human resource functions including payroll generation and distribution, tax withholding, state and federal reporting requirements and employer related insurance administration.
                </p>
            </div>
        ),
        contractToHire: (
            <div className="staffing-services-content">
                <h1 className="staffing-services-title">Contract-to-Hire Services</h1>
                <p className="staffing-services-description">
                    Contract-to-Hire Services give clients the ability to monitor the work performance of a Consultant prior to making a formal offer of employment. Think Development Systems offers Contract-to-Hire Services for many of its consultants with due regard to the concerns of all parties regarding hourly rates, timing of the employment offer, minimum work requirements and transaction modalities and costs.
                </p>
            </div>
        ),
        directHire: (
            <div className="staffing-services-content">
                <h1 className="staffing-services-title">Direct Hire Services</h1>
                <p className="staffing-services-description">
                    Our Direct Hire Services are a perfect solution for companies that need technical resources internally but may lack the recruiting expertise or resources to attract suitable candidates. We are able to recruit them for clients. Direct Hire Services also allows give companies to engage Think Development Consultants as employees.
                    For more specific staffing needs please contact our staffing division or email us at: hr@thinkdevelopment.com
                </p>
            </div>
        ),
        employers: (
            <div className="staffing-services-content">
                <h1 className="staffing-services-title">For Employers</h1>
                <p className="staffing-services-description">
                    We offer support throughout the entire recruiting process. If you are the consultant, we search for opportunities that will utilize your technical talent, thus helping you achieve your personal and professional goals. If you are the organization seeking highly talented IT professionals for short and long term projects, our recruiters will go far and beyond to find the resource to help you accomplish your corporate goal.
                    Once hired, our staff keeps in contact with the client and consultant to ensure satisfaction at all levels during the entire project.
                    <br /><br />
                    <strong>Why Do Employers Partner With Think Staffing Team?</strong>
                    <br /><br />
                    Dear Employer or HR Professional,
                    <br />
                    If you've had enough sorting through an ocean of unqualified candidates you find on the job boards or you are simply tired of the typical, traditional search model of "overprice and under deliver".
                    <br /><br />
                    You must read on
                    <br /><br />
                    We all wish we had better, more talented employees. Our jobs would be much easier and we would be far more efficient. But what is the cost? To date it has been as ridiculous as 30% of the new employee’s 1st year income. What are you getting? Is it worth the price? A more valuable loss is your time.
                    <br /><br />
                    We at TDS decided there had to be a better, more effective, and lower priced model. We started with a question; “How do you become the largest and most successful employee search firm in the South East Region answer seemed too simple
                    <br />
                    "Stand behind your work, performance and pricing that cannot be duplicated in the marketplace."
                    <br />
                    So we built our entire system around this promise.
                    <br /><br />
                    But you won't find better rates
                    <br /><br />
                    We'll do all the work. we'll prescreen and interview all candidates and schedule all the interviews We always maintain the most reasonable rates in the industry. So if you want to see a better return on your outsourced recruiting dollars, contact us today and we'll get you started.
                    <br /><br />
                    Email: hr@thinkdevelopment.com or call us at (770) 723-7777 today and experience the TDS Staffing difference.
                </p>
            </div>
        ),
        candidates: (
            <div className="staffing-services-content">
                <h1 className="staffing-services-title">For Candidates</h1>
                <p className="staffing-services-description">
                    Think Development Systems is committed to helping job seekers with the most suitable and attractive positions. Due to strategic partnerships with a wide variety of government and private organizations, and business with numerous Fortune 1000 companies, it enjoys a vantage position in assisting applicants. Our constant interaction with government and corporate sectors enables us to identify the vast opportunities that keep opening up for an appropriate match for your skills. Our successful track record has relied on identifying your strengths, establishing career goals, and locating the right match for your expertise.<br /><br />
                    Since inception in 1998, Think Development Systems, Inc., with its headquarters in metro Atlanta, has been catering to all sectors of industry and government. Think Development Systems, Inc. is certified as an MBE Corporation by the GMSDC, and recognized as an Asian Business Enterprise and Female Business Enterprise by the EBO program of the City of Atlanta. <br /><br />Think Development Systems, Inc has rich experience in providing technical staffing services, being a prime contractor to the State of Georgia, State of South Carolina, City of Atlanta and State of North Carolina. We have partnered with AT&T, CIGNA, IBM, UBS, Bank of America and SITA, among a host of organizations.
                    From the company's inception as a Staff Augmentation and Services provider, TDS has made significant advancements in the IT industry to provide Service Oriented Architecture to numerous industries. Think Development Systems has specialized divisions for Data Visualization, Custom Software Applications, Data Archiving, Data Warehousing and Data Architecture, Project Management, Computer Network Solutions, to name just a few. Importantly, what sets us apart in the IT Industry is our range of proprietary products. Our partner-organization’s satisfaction is the mainstay of our success, and our long association with each of them speaks about the quality of our work.
                </p>
            </div>
        ),
    };

    return (
        <div className="staffing-services">
            <div className="menu">
                <button onClick={() => setActiveSection('overview')} className="menu-button">Overview</button>
                <button onClick={() => setActiveSection('contractServices')} className="menu-button">Contract Services</button>
                <button onClick={() => setActiveSection('contractToHire')} className="menu-button">Contract-to-Hire</button>
                <button onClick={() => setActiveSection('directHire')} className="menu-button">Direct Hire</button>
                <button onClick={() => setActiveSection('employers')} className="menu-button">For Employers</button>
                <button onClick={() => setActiveSection('candidates')} className="menu-button">For Candidates</button>
            </div>

            <div className="content-container">
                {/* Dynamically display the active section */}
                {sections[activeSection]}
            </div>
        </div>
    );
};

export default StaffingServices;

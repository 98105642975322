import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./component/Header/Header";
import "./App.css";
import Content from "./component/Content/Content";
import Footer from "./component/Footer/Footer";
import Drone from "./component/Drone/Drone";
import CompanyOverview from "./component/Content/CompanyOverview";
import NovaComponent from "./component/Content/Nova";
import ThinkUASComponent from "./component/Content/ThinkUASComponent";
import ApplicationDevelopment from "./component/Application/ApplicationDevelopment";
import GovernmentComponent from "./component/Governement/Government";
import FinancialSectorComponent from "./component/Financial/FinancialSector";
import FoodBeverage from "./component/FoodBeverage/FoodBeverage";
import StaffingServices from "./component/Staffing/StaffingServices";
import CareerComponent from "./component/Career/CareerComponent";
import MachineLearning from "./component/Application/MachineLearning";
import ClientLogos from "./component/Client/Client";
import Sitemap from "./component/Sitemap/Sitemap";
import Team from "./component/Teams/Teams";
import HowWeWork from "./component/HowWeWork/HowWeWork";
import AIOverview from "./component/Application/ArtificialIntelligence";
import ContactForm from "./component/Contact/Contact";
import JobSeekersForm from "./component/JobSeekersForm/JobSeekersForm";

const App = () => {
  return (
    <Router>
      <MainContent />
    </Router>
  );
};

const MainContent = () => {
  const location = useLocation();
  const isSitemap = location.pathname === "/site-map";
  const [mouseX, setMouseX] = useState(50); // Only track X movement for left/right effect

  // ✅ Background Images Mapping
  const backgroundImages = {
    "/": require("./asset/technology.jpg"),
    "/company-overview": require("./asset/OVERVIEW.jpg"),
    "/think-nova": require("./asset/technology.jpg"),
    "/application-development": require("./asset/application.jpg"),
    // "/Artificial-Intelligence": require("./asset/technology.jpg"),
    "/how-we-work": require("./asset/hOW-WE-WORK.jpg"),
    "/Contact-us": require("./asset/contact.jpg"),
    "/site-map": require("./asset/sm.jpg"),
    "/ItDemand": require("./asset/technology.jpg"),
    "/MachineLearning": require("./asset/ml.jpg"),
    "/FoodBeverage": require("./asset/food.jpg"),
    "/FinancialSector": require("./asset/financial.jpg"),
    "/government": require("./asset/government.jpg"),
    "/Career": require("./asset/technology.jpg"),
  };

  const backgroundImage =
    location.pathname in backgroundImages ? `url(${backgroundImages[location.pathname]})` : "none";

  const handleMouseMove = (e) => {
    const x = (e.clientX / window.innerWidth) * 30 - 15; 
    setMouseX(x);
  };

  return (
    <div onMouseMove={handleMouseMove}>
      <Header />
      <div
        className={`content-wrapper ${isSitemap ? "no-min-height" : ""}`}
        style={{
          backgroundImage: backgroundImage !== "none" ? backgroundImage : "none",
          backgroundPosition: backgroundImage !== "none" ? `${50 + mouseX}% center` : "unset",
          transition: "background-position 0.1s ease-out",
        }}
      >
        <div className="routes-container">
          <Routes>
            <Route path="/" element={<Content />} />
            <Route path="/drone" element={<Drone />} />
            <Route path="/company-overview" element={<CompanyOverview />} />
            <Route path="/think-nova" element={<NovaComponent />} />
            <Route path="/think-uas" element={<ThinkUASComponent />} />
            <Route path="/application-development" element={<ApplicationDevelopment />} />
            <Route path="/government" element={<GovernmentComponent />} />
            <Route path="/FinancialSector" element={<FinancialSectorComponent />} />
            <Route path="/FoodBeverage" element={<FoodBeverage />} />
            <Route path="/ItDemand" element={<StaffingServices />} />
            <Route path="/Contact-us" element={<CareerComponent />} />
            <Route path="/MachineLearning" element={<MachineLearning />} />
            <Route path="/site-map" element={<Sitemap />} />
            <Route path="/teams" element={<Team />} />
            <Route path="/how-we-work" element={<HowWeWork />} />
            <Route path="/Artificial-Intelligence" element={<AIOverview />} />
            <Route path="/Career" element={<ContactForm />} />
            <Route path="/job-seekers" element={<JobSeekersForm/>}/>
          </Routes>
        </div>
      </div>
      <ClientLogos />
      <Footer />
    </div>
  );
};

export default App;

import React from "react";
import "../Content/ThinkUAS.css";
import drone from "../../asset/drone_flickr_1200.jpg";

const ThinkUASComponent = () => {
  return (
    <div className="uas-container">
      <div className="uas-image">
        <img src={drone} alt="Advanced Drone" className="uas-drone-image" />
      </div>

      <div className="uas-content">
        <h2 className="uas-title">Reshoring Manufacturing of Civilian and Military Drones in the USA</h2>
        <h3 className="uas-subtitle">Our Vision: Empowering Innovation. Ensuring Security. Building the Future.</h3>
        <p className="uas-text">
          In a rapidly evolving world, drones are not just tools—they are vital assets in both civilian and military applications. 
          At <strong>Think Development Systems</strong>, we are driven by innovation, sustainability, and security that empower our 
          country and economy, leading the charge to bring drone manufacturing back home to the United States. 
        </p>

        <h3 className="uas-heading">Why Re-Shore Drone Manufacturing?</h3>
        <ul className="uas-list">
          <li><strong>🔒 Strengthening National Security:</strong> Eliminates vulnerabilities in supply chains, protects intellectual property, and secures technologies.</li>
          <li><strong>💼 Boosting the U.S. Economy:</strong> Creates high-tech jobs, revitalizes American manufacturing, and fosters economic growth.</li>
          <li><strong>✅ Ensuring Quality & Reliability:</strong> Domestic manufacturing ensures rigorous quality control, adherence to industry standards, and supply chain security.</li>
        </ul>

        <h3 className="uas-heading">Applications Across Sectors</h3>
        <ul className="uas-list">
          <li><strong>🛡️ Military:</strong> Secure and mission-ready drones for defense operations.</li>
          <li><strong>🌾 Civilian:</strong> Drones for agriculture, disaster response, and infrastructure monitoring.</li>
          <li><strong>📦 Commercial:</strong> Logistics, media, and new emerging industries.</li>
        </ul>

        <h3 className="uas-heading">Why Choose Think Development Systems Drones?</h3>
        <ul className="uas-list">
          <li><strong>🇺🇸 American-Made Excellence:</strong> From design to final assembly, we ensure full U.S. production.</li>
          <li><strong>🌱 Commitment to Sustainability:</strong> Eco-friendly practices in manufacturing for a sustainable future.</li>
          <li><strong>🔐 Security You Can Trust:</strong> Domestic manufacturing protects sensitive technologies and critical data.</li>
        </ul>
      </div>
    </div>
  );
};

export default ThinkUASComponent;

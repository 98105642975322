import React from "react";
import Introduction from "../Introduction/Introduction";
import ITSolutions from "../ITSolution/Itsolution";

function Content() {
  return (
    <>
    <Introduction/>
    <ITSolutions/>
    </> 
  );
}

export default Content;

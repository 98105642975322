import React from "react";
import governmentImage1 from "../../asset/federal_img.jpg";
import governmentImage2 from "..//../asset/database_img.jpg";
import "./GovernmentComponent.css";

const GovernmentComponent = () => {
  return (
    <div className="container">
      <div className="government-container">
        <h2 className="government-title">Government</h2>
        <p className="government-text">
          Globalization, technological dynamism and other modern challenges are
          driving a need for complex, collaborative government operations. Ageing
          populations, economic slowdowns, budget shortfalls and security issues
          are adding difficulties. In facing these challenges, governments around
          the world are transforming how they work.
        </p>
        <p className="government-text">
          TDS has assisted government agencies in addressing these challenges by
          modernizing IT infrastructure, increasing operational responsiveness and
          resource utilization, and promoting accountability. With improved
          business processes and collaborative networks, governments can empower
          communities, citizens and staff with their products and services. TDS
          professional services group has rich experience in providing a platform
          for aggregating processes that can result in a global "real time" view,
          which enables Government to undertake e-governance initiatives.
        </p>
        <h3 className="government-subtitle">TDS e-Government – Solution Architecture</h3>
        <p className="government-text">
          TDS, with its technology partnership as Ready Partner with Red Hat -
          JBoss and biometric product offering, has a highly secured e-Governance
          architecture.
        </p>
        <div className="government-images">
          <img src={governmentImage1} alt="Federal government building" className="government-image" />
        </div>
        
        <h4 className="government-subtitle">TDS e-Government – solution architecture</h4>
        <div className="government-images">
          <img src={governmentImage2} alt="Secure database infrastructure" className="government-image" />
        </div>
      </div>
    </div>
  );
};

export default GovernmentComponent;

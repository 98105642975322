import React from "react";
import "./ITExpertiseSection.css"; // Import the CSS file for styling
import {
  FaLaptopCode,
  FaServer,
  FaCogs,
  FaRobot,
  FaShieldAlt,
  FaShieldVirus,
  FaIndustry,
  FaUsers,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import bridgeTalentImg from "../../asset/bridge-talent.jpg";
import enhanceEfficiencyImg from "../../asset/enhance-efficiency.webp";
import accelerateTransformationImg from "../../asset/accelerate-transformation.webp";

const ITExpertiseSection = () => {
  const navigate = useNavigate();
  return (
    <div className="it-expertise-section">
      <div className="header-section">
        <h1>
          Empower Your Business with On-Demand IT Expertise & AI-Driven
          Precision Manufacturing
        </h1>
      </div>
      <div className="it-expertise">
        <h2>Seamless IT Expertise Across Every Industry</h2>
        <p style={{ color: "black" }}>
          In today's rapidly evolving digital landscape, having the right IT
          professionals is critical to maintaining a competitive edge. Our
          On-Demand IT Expertise and Flexible IT Workforce Solution services
          ensure that businesses in every sector—including government,
          healthcare, finance, manufacturing, retail, and beyond—have access to
          top-tier talent precisely when they need it.
        </p>
        <div className="it-expertise-list">
          <div className="it-expertise-item">
            <img
              src={bridgeTalentImg}
              alt="Bridge Talent Gaps"
              className="it-expertise-img"
            />
            <div>
              <h3>
                <strong>Bridge Talent Gaps:</strong>
              </h3>
              <p style={{ color: "black" }}>
                Quickly fill IT positions with highly skilled professionals who
                match your exact needs.
              </p>
            </div>
          </div>
          <div className="it-expertise-item">
            <img
              src={enhanceEfficiencyImg}
              alt="Enhance Operational Efficiency"
              className="it-expertise-img"
            />
            <div>
              <h3>
                <strong>Enhance Operational Efficiency:</strong>
              </h3>
              <p style={{ color: "black" }}>
                Bring in industry-leading experts without the overhead costs of
                full-time employees.
              </p>
            </div>
          </div>
          <div className="it-expertise-item">
            <img
              src={accelerateTransformationImg}
              alt="Accelerate Digital Transformation"
              className="it-expertise-img"
            />
            <div>
              <h3>
                <strong>Accelerate Digital Transformation:</strong>
              </h3>
              <p style={{ color: "black" }}>
                Stay ahead of the competition by integrating the latest
                technologies into your operations.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Government Solutions Section */}
      <div className="section government-solutions">
        <h2>
          <FaShieldVirus className="icon" /> Government-Ready IT Solutions
        </h2>
        <ul>
          <li>Certified professionals with security clearances</li>
          <li>Compliance with regulatory frameworks</li>
          <li>Agile solutions for digital modernization</li>
          <li>Data security and cybersecurity expertise</li>
        </ul>
      </div>
      <div className="icon-cards">
        {[
          {
            icon: <FaLaptopCode />,
            title: "IT Development",
            desc: "We build scalable applications.",
          },
          {
            icon: <FaServer />,
            title: "Cloud Solutions",
            desc: "Secure & reliable cloud-based infrastructures.",
          },
          {
            icon: <FaCogs />,
            title: "Automation",
            desc: "Automate workflows for efficiency.",
          },
          {
            icon: <FaRobot />,
            title: "AI & ML",
            desc: "Leverage AI-powered decision-making tools.",
          },
          {
            icon: <FaShieldAlt />,
            title: "Cybersecurity",
            desc: "Protect data with advanced security measures.",
          },
        ].map((item, index) => (
          <div key={index} className="card">
            <div className="card-icon">{item.icon}</div>
            <h3>{item.title}</h3>
            <p style={{ color: "black" }}>{item.desc}</p>
          </div>
        ))}
      </div>

      <div className="d-flex section justify-content-center">
        <div className=" ai-manufacturing ">
          <h2>
            <FaIndustry className="icon" /> AI-Powered Precision Manufacturing
          </h2>
          <ul style={{ listStyle: "none", padding: 0, margin: 0, textAlign: "center" }}>

            <li>
              <strong>Optimizing Production Efficiency:</strong> AI-driven
              analytics reduce downtime.
            </li>
            <li>
              <strong>Predictive Maintenance:</strong> Minimize machine failures
              with AI-powered monitoring and alerts.
            </li>
            <li>
              <strong>Smart Automation:</strong> Leverage robotics and machine
              learning to streamline complex manufacturing processes.
            </li>
            <li>
              <strong>Enhanced Quality Control:</strong> AI-driven visual
              inspection ensures flawless product integrity.
            </li>
          </ul>
        </div>
      </div>
      <div className="section why-choose-us mt-5">
        <h2>
          <FaUsers className="icon" /> Why Choose Us?
        </h2>
        <ul>
          <li>
            <strong>Global Talent Pool:</strong> Access a diverse network of IT
            and AI professionals.
          </li>
          <li>
            <strong>Industry-Specific Expertise:</strong> Solutions tailored to
            the unique demands of your sector.
          </li>
          <li>
            <strong>Cutting-Edge Innovation:</strong> Harness the power of AI,
            machine learning, and automation to drive success.
          </li>
          <li>
            <strong>Scalability & Flexibility:</strong> Augment your workforce
            on-demand without long-term commitments.
          </li>
        </ul>
      </div>

      {/* Call to Action */}
      <div className="section cta-section text-center mt-5 mb-5">
        <h2>Transform Your Workforce Today</h2>
        <p>
          Whether you're in government, healthcare, finance, or manufacturing,
          our IT Staff Augmentation and AI-driven solutions will help you
          navigate digital transformation effortlessly.
        </p>
        <button
          className="cta-button"
          onClick={() => {
            navigate("/Contact-us");
            window.scrollTo(0, 0);
          }}
        >
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default ITExpertiseSection;

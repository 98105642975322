import React, { useState } from "react";
import "./HowWeWork.css";

const HowWeWork = () => {
  const [position, setPosition] = useState({ x: 50, y: 50 });

  const handleMouseMove = (e) => {
    const { innerWidth, innerHeight } = window;
    const x = (e.clientX / innerWidth) * 100;
    const y = (e.clientY / innerHeight) * 100;
    setPosition({ x, y });
  };

  return (
    <div
      className="how-we-work-container"
      style={{ backgroundPosition: `${position.x}% ${position.y}%` }}
      onMouseMove={handleMouseMove}
    >
      <h2>How We Work</h2>
      <p className="intro-text">
        At Think Development Systems Inc. (TDSI), our approach is centered on
        collaboration, innovation, and delivering tailored solutions that drive
        sustainable success for our clients.
      </p>
      <div className="sections-wrapper">
        <div className="section">
          <h3>Client-Centric Collaboration</h3>
          <p>
            We prioritize building strong partnerships with our clients, working
            closely to understand their unique challenges and objectives.
          </p>
        </div>
        <div className="section">
          <h3>Innovative and Cost-Effective Solutions</h3>
          <p>
            Leveraging our core expertise in software engineering, quality
            management, and project control, we craft innovative solutions
            designed to address complex business challenges.
          </p>
        </div>
        <div className="section">
          <h3>Agile and Adaptive Processes</h3>
          <p>
            Embracing agile methodologies, we maintain flexibility to adapt to
            evolving client requirements and market dynamics.
          </p>
        </div>
        <div className="section">
          <h3>Diverse Industry Expertise</h3>
          <p>
            Our extensive experience spans multiple sectors, including government,
            retail, distribution, and manufacturing.
          </p>
        </div>
        <div className="section">
          <h3>Commitment to Excellence</h3>
          <p>
            At TDSI, we are dedicated to upholding the highest standards of
            quality in every project.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowWeWork;

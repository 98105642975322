import { useState } from "react";
import './CareerForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    title: "",
    phone: "",
    address: "",
    country: "",
    company: "",
    email: "",
    city: "",
    state: "",
    zip: "",
    url: "",
    comments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted", formData);
  };

  return (
    <div className="new-contact-container">

      <div className="new-contact-form-container">
        <h2 className="new-contact-header">Contact Us</h2>
        <p className="new-contact-address">Think Development Systems, Inc., 6000 Live Oak Pkwy, Suite 116, Norcross, GA 30093</p>
        <p className="new-contact-address">Email: info@thinkdevelopment.com</p>
        <p className="new-contact-address">Phone: (770) 723 - 7777</p>
        <p className="new-contact-address">Fax: (770) 723 - 1530</p>
        <form onSubmit={handleSubmit} className="new-contact-form">
          <table>
            <tbody>
  
              <tr>
                <td><input type="text" name="firstName" placeholder="First Name*" value={formData.firstName} onChange={handleChange} required /></td>
                <td><input type="text" name="lastName" placeholder="Last Name*" value={formData.lastName} onChange={handleChange} required /></td>
                <td><input type="text" name="title" placeholder="Title*" value={formData.title} onChange={handleChange} required /></td>
                <td><input type="tel" name="phone" placeholder="Phone*" value={formData.phone} onChange={handleChange} required /></td>
              </tr>

   
              <tr>
                <td colSpan="2"><input type="text" name="company" placeholder="Company*" value={formData.company} onChange={handleChange} required /></td>
                <td colSpan="2"><input type="email" name="email" placeholder="Email*" value={formData.email} onChange={handleChange} required /></td>
              </tr>

              <tr>
                <td colSpan="4"><input type="text" name="address" placeholder="Address*" value={formData.address} onChange={handleChange} required /></td>
              </tr>

              <tr>
                <td><input type="text" name="city" placeholder="City*" value={formData.city} onChange={handleChange} required /></td>
                <td><input type="text" name="state" placeholder="State*" value={formData.state} onChange={handleChange} required /></td>
                <td><input type="text" name="zip" placeholder="Zip*" value={formData.zip} onChange={handleChange} required /></td>
                <td>
                <select name="country" value={formData.country} onChange={handleChange} required>
  <option value="">--Select Country--</option>
  {[
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria", 
    "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", 
    "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", 
    "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica", 
    "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", 
    "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon", 
    "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", 
    "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", 
    "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", 
    "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", 
    "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", 
    "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", 
    "Nigeria", "North Korea", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea", 
    "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", 
    "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", 
    "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Korea", 
    "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", 
    "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", 
    "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan", 
    "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
  ].map((country) => (
    <option key={country} value={country}>{country}</option>
  ))}
</select>

                </td>
              </tr>

              <tr>
                <td colSpan="4"><input type="url" name="url" placeholder="Website URL*" value={formData.url} onChange={handleChange} required /></td>
              </tr>

              <tr>
                <td colSpan="4">
                  <textarea name="comments" placeholder="Any questions or comments?" value={formData.comments} onChange={handleChange} />
                </td>
              </tr>
              <tr>
                <td colSpan="4" className="new-form-submit">
                  <button type="submit">Submit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;

import React, { useState } from "react";
import "./JobSeekersForm.css";

export default function JobSeekersForm() {
  const [formData, setFormData] = useState({
    position: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    country: "",
    callTime: "",
    resume: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted:", formData);
  };

  return (
    <div className="container">
      <div className="contact-container">
        <h2 className="form-title">Job Seekers - Apply Now</h2>
        <p className="form-description">
          Fill out the form below and attach your resume to get noticed by our consultants.
        </p>

        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label>Position Applying For*</label>
            <input type="text" name="position" value={formData.position} onChange={handleChange} required />
          </div>

          <div className="form-group">
            <label>Email ID*</label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>First Name*</label>
              <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label>Last Name*</label>
              <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
            </div>
          </div>

          <div className="form-group">
            <label>Telephone Number*</label>
            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
          </div>

          <div className="form-group">
            <label>Country*</label>
            <select name="country" value={formData.country} onChange={handleChange} required>
              <option value="">--Select--</option>
              <option value="India">India</option>
              <option value="USA">USA</option>
              <option value="UK">UK</option>
              <option value="Australia">Australia</option>
            </select>
          </div>

          <div className="form-group">
            <label>Suggested Time to Call*</label>
            <input type="text" name="callTime" value={formData.callTime} onChange={handleChange} required />
          </div>

          <div className="form-group file-input">
            <label>Attach Resume (PDF/DOC)*</label>
            <input type="file" name="resume" accept=".pdf,.doc,.docx" onChange={handleFileChange} required />
          </div>

          <button type="submit" className="submit-btn">Submit</button>
        </form>
      </div>
    </div>
  );
}

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Footer.css";
import twiiter from "../../asset/twitter.png";
import link from "../../asset/linkedin.png";
import facebook from "../../asset/facebook.png";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // This will scroll to the top after navigation
  };

  return (
    <footer className="footer">
      <div className="container footer-container">
        <div className="footer-logo">
          <h2>THINK DEVELOPMENT SYSTEMS</h2>
          <p>Innovate. Learn. Succeed.</p>
        </div>

        {/* Footer Navigation Section */}
        <div className="footer-nav">
          <div className="footer-links">
            <b>Quick Links</b>
            <Link to="/" className="footer-link" onClick={() => handleNavigate("/")}>
              Home
            </Link>
            <Link to="/company-overview" className="footer-link" onClick={() => handleNavigate("/company-overview")}>
              Company Overview
            </Link>
            <Link to="/teams" className="footer-link" onClick={() => handleNavigate("/teams")}>
              Leadership Team
            </Link>
            <Link to="/how-we-work" className="footer-link" onClick={() => handleNavigate("/how-we-work")}>
              How We Work
            </Link>
          </div>
          <div className="footer-links">
            <b>Services</b>
            <Link to="/application-development" className="footer-link" onClick={() => handleNavigate("/application-development")}>
              Application Development
            </Link>
            <Link to="/government" className="footer-link" onClick={() => handleNavigate("/government")}>
              Government
            </Link>
            <Link to="/FoodBeverage" className="footer-link" onClick={() => handleNavigate("/FoodBeverage")}>
              Food & Beverage
            </Link>
          </div>
          <div className="footer-links">
            <b>Products</b>
            <Link to="/think-nova" className="footer-link" onClick={() => handleNavigate("/think-nova")}>
              Think Nova
            </Link>
            {/* <Link to="/think-uas" className="footer-link" onClick={() => handleNavigate("/think-uas")}>
              Think UAS
            </Link> */}
          </div>
          <div className="footer-links">
            <b>Technologies</b>
            <Link to="/Artificial-Intelligence" className="footer-link" onClick={() => handleNavigate("/Artificial-Intelligence")}>
              Artificial Intelligence
            </Link>
            <Link to="/MachineLearning" className="footer-link" onClick={() => handleNavigate("/MachineLearning")}>
              Machine Learning
            </Link>
            <Link to="/drone" className="footer-link" onClick={() => handleNavigate("/drone")}>
              Drone
            </Link>
          </div>
          <div className="footer-links">
            <b>Other</b>
            <Link to="/ItDemand" className="footer-link" onClick={() => handleNavigate("/ItDemand")}>
              IT On Demand
            </Link>
            <Link to="/Contact-us" className="footer-link" onClick={() => handleNavigate("/Contact-us")}>
              Contact Us
            </Link>
            <Link to="/Career" className="footer-link" onClick={() => handleNavigate("/Career")}>
              Career
            </Link>
            {/* <Link to="/site-map" className="footer-link" onClick={() => handleNavigate("/site-map")}>
              Sitemap
            </Link> */}
          </div>
        </div>
      </div>

      {/* Footer Social Media Section */}
      <div className="container footer-social">
        <p>Connect with us:</p>
        <div className="social-links">
        <img src={twiiter} alt="Company Logo" className="footer-logo-img" />
        <img src={link} alt="Company Logo" className="footer-logo-img" />
        <img src={facebook} alt="Company Logo" className="footer-logo-img" />
        </div>
      </div>

      {/* Footer Subscription Section */}
      <div className="container footer-subscribe">
        <p>Subscribe to our newsletter:</p>
        <form>
          <input
            type="email"
            placeholder="Enter your email"
            className="email-input"
          />
          <button type="submit" className="subscribe-button">
            Subscribe
          </button>
        </form>
      </div>

      {/* Footer Contact Section */}
      <div className="container footer-contact">
        <p>Contact Us:</p>
        <p>Email: info@thinkdevelopment.com</p>
        <p>Phone: (770) 723 - 7777</p>
        <p>Fax: (770) 723 - 1530</p>
        <p>
          Think Development Systems, Inc., 6000 Live Oak Pkwy, Suite 116, Norcross, GA 30093.
        </p>
      </div>

      <div className="container footer-bottom">
        <p>
          &copy; {new Date().getFullYear()} Think Development Systems. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import { Container, Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa"; // Import dropdown icon
import logo from "../../asset/logo_home.png"; // Ensure logo is in the correct path
import "../Header/header.css"; // Import styles
import { Link } from "react-router-dom";

function Header() {
  return (
    <Navbar expand="lg" className="custom-navbar">

        {/* Logo */}
        <Navbar.Brand href="#" className="d-flex align-items-center">
          <img src={logo} alt="Company Logo" className="navbar-logo" />
        </Navbar.Brand>

        {/* Mobile Toggle Button */}
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="custom-toggler"
        />

        <Navbar.Collapse id="basic-navbar-nav">
          {/* Navigation Links */}
          <Nav className="ms-auto nav-links">
            <Nav.Link href="/" className="nav-item-modern">
              Home
            </Nav.Link>

            {/* Dropdown for About Us */}
            <NavDropdown
              title={
                <>
                  About Us <FaChevronDown className="dropdown-icon" />
                </>
              }
              id="about-dropdown"
              className="custom-dropdown"
            >
              <NavDropdown.Item as={Link} to="/company-overview">Company Overview</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/teams">
                Leadership Team
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/how-we-work">How we work</NavDropdown.Item>
            </NavDropdown>

            {/* Dropdown for Services */}
            <NavDropdown
              title={
                <>
                  Services <FaChevronDown className="dropdown-icon" />
                </>
              }
              id="services-dropdown"
              className="custom-dropdown"
            >
               <NavDropdown.Item as={Link} to="/application-development">
              Application Development
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/government">
                Government
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/FinancialSector">
                Financial Sector
              </NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/FoodBeverage">
                Food & Beverage
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title={
                <>
                  Product <FaChevronDown className="dropdown-icon" />
                </>
              }
              id="services-dropdown"
              className="custom-dropdown"
            >
              <NavDropdown.Item as={Link} to="/think-nova">
                Think Nova (Next-Gen Operational Voice Automation)
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/think-uas">
                Think UAS (Unmanned Aerial Systems)
              </NavDropdown.Item> */}
            </NavDropdown>
            <NavDropdown
              title={
                <>
                   Technology <FaChevronDown className="dropdown-icon" />
                </>
              }
              id="services-dropdown"
              className="custom-dropdown "
            >
          
              <NavDropdown.Item as={Link} to="/MachineLearning">
              Machine Learning
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Artificial-Intelligence">
              Artificial Intelligence
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/drone">
              Drone
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title={
                <>
                   Consulting <FaChevronDown className="dropdown-icon" />
                </>
              }
              id="services-dropdown"
              className="custom-dropdown  d-xl-block"
            >
              <NavDropdown.Item as={Link} to="/ItDemand">
              IT On Demand
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title={
                <>
                   Other <FaChevronDown className="dropdown-icon" />
                </>
              }
              id="services-dropdown"
              className="custom-dropdown d-xl-block"
              data-bs-toggle="dropdown"
            >
              <NavDropdown.Item as={Link} to="/Contact-us">
              Contact us
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/Career">
              Career
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/site-map">
              Sitemap
              </NavDropdown.Item> */}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>

    </Navbar>
  );
}

export default Header;

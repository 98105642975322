import React from "react";
import "./ContentManagement.css"; // Import the styles

const ContentManagement = () => {
  return (
    <div className="cms-container">
      <h2 className="cms-title">Content Management Solutions</h2>
      <p className="cms-text">
        TDS, as a JBoss Ready partner, provides Enterprise Content Management solutions through its Red Hat partner network.
      </p>
      <p className="cms-text">
        TDS Portal framework covers Enterprise Content Management with a plug-in biometric security module that enables 
        government and corporate America to tackle security challenges.
      </p>
    </div>
  );
};

export default ContentManagement;

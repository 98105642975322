import React from "react";
import "./ContinuationReengineering.css"; 

const ContinuationReengineering = () => {
  return (
    <div className="reengineering-container">
      <h2 className="reengineering-title">Continuation Re-engineering and Migration</h2>
      <p className="reengineering-text">
        TDS's Continuation Re-engineering solutions provide strategies, methodologies, techniques, and tools that help clients
        continue to re-engineer, rather than replace existing legacy systems to keep pace with evolving business and technology
        requirements.
      </p>
      <p className="reengineering-text">
        We combine our knowledge of clients' business processes with sophisticated re-engineering tools to provide a highly
        flexible migration path to IT solutions of the future.
      </p>

      <h3 className="reengineering-subtitle">Services We Offer:</h3>
      <ul className="reengineering-list">
        <li>Legacy System Migration</li>
        <li>Portal Migration</li>
        <li>Application Migration</li>
        <li>Database Re-engineering/Migration</li>
        <li>Language Migration</li>
        <li>Operating Systems Migration</li>
        <li>Code Restructuring</li>
        <li>Performance Optimization</li>
        <li>Document Generation</li>
      </ul>

      <p className="reengineering-text">
        We have extensive experience in transitioning old systems to newer technologies. Our extensive experience in working
        with legacy systems, coupled with an in-depth knowledge of emerging e-business technologies, enables us to offer innovative
        Web-based solutions that leverage both the power of the Internet and the client's legacy environment.
      </p>

      <h3 className="reengineering-subtitle">Our Approach:</h3>
      <ul className="reengineering-list">
        <li>Re-engineering legacy systems into reusable components</li>
        <li>Wrapping components under standard architectures</li>
        <li>Re-designing legacy user interfaces into Web-based graphical interfaces</li>
        <li>Accessing legacy transactions and integrating them with Web-based solutions</li>
      </ul>

    </div>
  );
};

export default ContinuationReengineering;

import "./ContactForm.css"; 
import { useNavigate } from "react-router-dom";

export default function ContactForm() {

  const jobOpportunities = [
    // {
    //   title: "Digital Accessibility Specialist",
    //   summary: "We are seeking a detail-oriented and passionate Digital Accessibility Specialist to support the Colorado Department of Education (CDE) in ensuring that digital content and technology meet accessibility standards.",
    //   responsibilities: [
    //     "Review and remediate digital documents (PDFs, Word, Excel, and PowerPoint) to meet WCAG 2.1 A/AA and Section 508 compliance standards.",
    //     "Utilize CommonLook PDF to assess and improve the accessibility of PDF documents.",
    //     "Train internal staff on digital accessibility best practices, including document remediation, website accessibility, and inclusive design principles.",
    //     // Add more responsibilities as needed
    //   ],
    //   requiredQualifications: [
    //     "1+ year of experience working with CommonLook PDF to remediate documents.",
    //     "Experience training internal staff on accessibility best practices and tools.",
    //     "Strong knowledge of WCAG 2.1 A/AA, Section 508, and ADA digital accessibility standards.",
    //     // Add more qualifications as needed
    //   ],
    //   preferredQualifications: [
    //     "CPACC, WAS, or other accessibility-related certifications.",
    //     "Experience with accessible procurement processes and reviewing VPATs.",
    //     // Add more preferred qualifications as needed
    //   ]
    // },
    // // Add more job opportunities as needed
  ];

  const navigate = useNavigate(); // Initialize navigation

  const handleApplyNow = () => {
    navigate("/job-seekers"); // Navigate to Job Seekers page
    window.scrollTo(0, 0); // Scroll to top after navigation
  };

  return (
    <div className="contact-container">
      <div className="content-box">
        <h2>Why TDS?</h2>
        <p>
          At TDS, we believe great people make great companies, and great companies attract great people.  
          People, process, and technology are the cornerstones of our pursuit of excellence.  
          We keenly promote an open culture, encourage feedback, and actively transform it into action.  
          Our goal is to ensure the best environment where people can perform and deliver their best to achieve excellence.
        </p>
      </div>

      <div className="content-box">
        <h2>Current Opportunities</h2>
        {jobOpportunities.map((job, index) => (
          <div key={index} className="job-opportunity">
            <h3>{job.title}</h3>
            <p>{job.summary}</p>
            <h4>Key Responsibilities:</h4>
            <ul>
              {job.responsibilities.map((responsibility, i) => (
                <li key={i}>{responsibility}</li>
              ))}
            </ul>
            <h4>Required Competencies & Qualifications:</h4>
            <ul>
              {job.requiredQualifications.map((qualification, i) => (
                <li key={i}>{qualification}</li>
              ))}
            </ul>
            <h4>Preferred Qualifications:</h4>
            <ul>
              {job.preferredQualifications.map((preferred, i) => (
                <li key={i}>{preferred}</li>
              ))}
            </ul>
          </div>
        ))}
        <button className="apply-btn" onClick={handleApplyNow}>Apply Now</button>
      </div>
    </div>
  );
}
import "../Content/overview.css";

const CompanyOverview = () => {
    return (
      <div className="">
        <div className="company-overview">
          <h2 className="company-title">Company Overview</h2>
          <p className="company-text">
            Headquartered in Atlanta, Think Development Systems Inc. (TDSI) is a
            premier Products and Global Information Technology Consulting and
            Professional Services firm dedicated to innovation and excellence.
          </p>
          <p className="company-text">
            With our strong foundation in Staff Augmentation and Professional
            Services, TDSI empowers businesses by deploying top talent and
            delivering impactful solutions that drive success.
          </p>
          <p className="company-text">
            We offer a unique suite of software tools that seamlessly integrate
            with legacy systems and firmware devices, ensuring secure and
            efficient access to sensitive information. Our Think SCM™ solutions
            redefine Supply Chain Management, offering unparalleled procurement
            solutions tailored to your business needs.
          </p>
          <p className="company-text">
            At TDS, we collaborate closely with our clients to transform ideas
            into cost-effective and sustainable business solutions. Leveraging our
            expertise in Software Engineering, Quality Management, and Project
            Control, we create cutting-edge software solutions that tackle today’s
            challenges while safeguarding critical information. We don’t just
            promise cost-efficiency—we deliver it.
          </p>
        </div>
      </div>
    );
  };
  
  export default CompanyOverview;
import React from 'react';
import './PortalApplications.css';

const PortalApplications = () => {
  return (
    <div className="portal-container">
      <h1 className="portal-title">Portal Applications</h1>
      <p className="portal-text">
        We provide complete portal solutions encompassing Web Information Portals, e-Commerce Portals, and Enterprise Knowledge/Information Portals. We have significant expertise in:
      </p>
      <ul className="portal-list">
        <li>Large Scale Transaction Systems</li>
        <li>Large Databases</li>
        <li>High Availability Requirements</li>
      </ul>
      <p className="portal-text">
        TDS, as JBoss Ready partner provides a complete solution based on JBoss Enterprise Portal Platform. JBoss Enterprise Portal Platform facilitates the delivery of web-based composite applications and high-performance web presences. Through its agile, reusable framework, customers can minimize the cost and complexity of their web infrastructures.
        <br/> <br/> Its use of open standards mitigates the risk of vendor lock-in, ensuring compatibility. As an integral component of JBoss Enterprise Middleware, the large and vibrant JBoss.org developer community fosters its continued innovation and enterprise quality. And it's deployed on JBoss Enterprise Application Platform—the industry’s #1 JavaEE-certified application platform ensuring performance, scalability, and a reliable and straightforward path to implementation.
      </p>
      <h2 className="portal-subtitle">TDS BI/BV Solution Benefits</h2>
      <ul className="portal-benefits">
        <li>
          <strong className='strong'>Provides the information needed to arrive at faster, better decisions.</strong> By integrating data from across the enterprise, delivering self-service reporting and analysis. IT spends less time responding to users’ requests and business users spend less time looking for information, so more time is spent on making faster, better decisions.
        </li>
        <li>
          <strong className='strong'>Increases data consistency, enables driven decisions, streamlines administration.</strong> Through centrally managed data, shared metadata and security, TDS Enterprise BI /BV solutions deliver consistent representation and control of information by providing reliable results that can be easily traced back to the source.
        </li>
        <li>
          <strong className='strong'>Minimizes risk and optimizes performance.</strong> IT managers deploying BI /BV solutions can leverage previous investments and integrate BI quickly into the existing environment.
        </li>
        <li>
          <strong className='strong'>Lowers Total Cost of Ownership with a solution that meets everyone’s needs.</strong> TDS ensures that its solutions have the breadth of BI/BV capabilities to meet the needs of every type of user: managers looking for dashboards; groups that want to view or build reports on the Web; analysts or power users who need advanced data exploration; and IT staff who need to deploy, manage, control and maintain the entire solution.
        </li>
      </ul>
    </div>
  );
};

export default PortalApplications;

import React from "react";
import "../Introduction/intro.css";
import logo from "../../asset/year.png";
import video from "../../asset/introduction.mp4"; // Import the video file

function Introduction() {
  return (
    <div className="intro-container">
      {/* Use the imported video as the background */}
      <video autoPlay loop muted>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <img src={logo} alt="Logo" className="logo" />
      <h1 className="display-4 fw-bold slide-in">THE AXLE OF ARTIFICIAL INTELLIGENCE</h1>
    </div>
  );
}

export default Introduction;

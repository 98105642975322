import React from "react";
import "./BusinessIntelligence.css";

const BusinessIntelligence = () => {
  const benefits = [
    {
      title: "Faster & Better Decisions",
      description:
        "Integrates data from across the enterprise, allowing self-reporting and analysis, reducing IT workload.",
    },
    {
      title: "Increased Data Consistency",
      description:
        "Centrally managed data ensures reliable results and a seamless flow of accurate information.",
    },
    {
      title: "Minimized Risk & Optimized Performance",
      description:
        "Leverages previous investments and integrates BI solutions smoothly into existing environments.",
    },
    {
      title: "Lower Total Cost of Ownership",
      description:
        "Comprehensive BI/BV capabilities to meet the needs of managers, analysts, and IT staff.",
    },
  ];

  return (
    <div className="bi-container">
      <h2 className="bi-title">Business Intelligence & Business Visualization</h2>
      <p className="bi-text">
        The ability to collect and analyze internal and external data plays a crucial role in knowledge generation and business value. Our BI/BV solutions help organizations make informed decisions, enhance financial management, ensure regulatory compliance, and improve customer service.
      </p>

      <p className="bi-text">
        We leverage our expertise in various software technologies to deliver customized BI solutions that enhance existing applications and maximize software investments.
      </p>

      <div className="bi-grid">
        {benefits.map((benefit, index) => (
          <div key={index} className="bi-card">
            <h3>{benefit.title}</h3>
            <p>{benefit.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessIntelligence;

import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import "../Application/Dashboard.css";
import enterpriseImg from "../../asset/services_over.png";

const EnterpriseSoftware = () => {
  return (
    <div className="enterprise-container">
      <h2 className="enterprise-title">Enterprise Software Applications</h2>
      <img src={enterpriseImg} alt="Enterprise Solutions" className="enterprise-image" />
      <p className="enterprise-description">
        TDS Professional Service Group is globally backed by partners assessed for software practice and <span className="highlight">ISO 9001</span> for services.
      </p>
      <p className="enterprise-description">
        TDS's global technology management expertise, coupled with a technology partnership with <span className="highlight">JBOSS middleware</span>, makes it a unique service provider.
      </p>

      <div className="enterprise-list">
        <div className="enterprise-item">
          <FaCheckCircle className="icon" />
          Globally recognized technology management expertise
        </div>
        <div className="enterprise-item">
          <FaCheckCircle className="icon" />
           ISO 9001 certifications
        </div>
        <div className="enterprise-item">
          <FaCheckCircle className="icon" />
          Strategic partnership with JBOSS middleware
        </div>
      </div>
    </div>
  );
};

export default EnterpriseSoftware;

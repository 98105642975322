import React from "react";
import videoFile from "../../asset/WhatsApp Video 2025-02-14 at 9.19.05 AM.mp4";
import "./drone.css"; // Import a CSS file for animations and responsive styles
import { text } from "framer-motion/client";

function Drone() {
  const styles = {
    container: {
      maxWidth: "1200px",
      margin: "0 auto",
      padding: "40px 20px",
      backgroundColor: "#1569AA",
      borderRadius: "12px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      color: "#fff",
    },
    title: {
      fontSize: "clamp(2rem, 5vw, 2.8rem)", // Responsive font size
      color: "#fff",
      marginBottom: "20px",
      fontWeight: "700",
      textAlign: "center",
    },
    subtitle: {
      fontSize: "clamp(1rem, 3vw, 1.4rem)", // Responsive font size
      color: "#fff",
      marginBottom: "40px",
      textAlign: "center",
      fontWeight: "500",
    },
    videoContainer: {
      width: "100%",
      marginBottom: "40px",
      borderRadius: "12px",
      overflow: "hidden",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    },
    cardContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "20px",
      marginBottom: "40px",
    },
    card: {
      flex: "1 1 300px",
      backgroundColor: "#ffffff",
      border: "1px solid #e0e0e0",
      borderRadius: "12px",
      padding: "24px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      transition: "transform 0.3s, box-shadow 0.3s",
    },
    cardTitle: {
      fontSize: "clamp(1.2rem, 3vw, 1.6rem)", // Responsive font size
      color: "#124870",
      marginBottom: "16px",
      fontWeight: "600",
    },
    cardText: {
      fontSize: "clamp(0.9rem, 2.5vw, 1rem)", // Responsive font size
      color: "#555",
      lineHeight: "1.6",
    },
    highlight: {
      fontSize: "clamp(1.5rem, 4vw, 2.2rem)", // Responsive font size
      color: "#fff",
      marginBottom: "24px",
      fontWeight: "700",
      textAlign: "center",
    },
    text: {
      fontSize: "clamp(0.9rem, 2.5vw, 1.1rem)", // Responsive font size
      color: "#fff",
      marginBottom: "24px",
      textAlign: "center",
      lineHeight: "1.6",
    },
    ctaButton: {
      backgroundColor: "#124870",
      color: "#fff",
      padding: "12px 24px",
      border: "none",
      borderRadius: "8px",
      fontSize: "1rem",
      fontWeight: "600",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    list: {
      listStyleType: "none",
      padding: "0",
      textAlign: "left",
      maxWidth: "1000px",
      margin: "0 auto",
    },
    listItem: {
      textAlign:"center",
      fontSize: "clamp(0.9rem, 2.5vw, 1rem)", // Responsive font size
      color: "#fff",
      marginBottom: "12px",
      paddingLeft: "20px",
      position: "relative",
    },
    listItemBullet: {
      position: "absolute",
      left: "0",
      top: "8px",
      width: "8px",
      height: "8px",
      backgroundColor: "#1569AA",
      borderRadius: "50%",
    },
  };

  return (
    <div style={styles.container} className="fade-in">
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <h1 style={styles.title}>
          Elevating the Future: Scaling Drone Innovation, Manufacturing & Airspace
          Security for Defence and the Commercial Sector
        </h1>
        <p style={styles.subtitle}>
          Think Development Systems - Think ITech Drone Technology
        </p>
      </div>

      <div style={styles.videoContainer} className="scale-up">
        <video width="100%" height="auto" controls>
          <source src={videoFile} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div style={styles.cardContainer}>
        <div style={styles.card} className="card-hover">
          <h2 style={styles.cardTitle}>Precision Engineering & Manufacturing Excellence</h2>
          <p style={styles.cardText}>
            Our advanced manufacturing facilities are equipped to produce
            high-performance drones tailored for mission-critical operations.
            From surveillance and reconnaissance to cargo transport and aerial
            monitoring, our UAS platforms are built for reliability, endurance,
            and efficiency.
          </p>
        </div>
        <div style={styles.card} className="card-hover">
          <h2 style={styles.cardTitle}>Defense-Grade UAS Solutions</h2>
          <p style={styles.cardText}>
            We deliver robust, mission-ready drones that enhance security,
            intelligence, and operational effectiveness for defense agencies and
            law enforcement. Our autonomous systems provide real-time data,
            secure communications, and seamless integration with existing
            defense technologies.
          </p>
        </div>
        <div style={styles.card} className="card-hover">
          <h2 style={styles.cardTitle}>Drone Technology for Local & State Law Enforcement</h2>
          <p style={styles.cardText}>
            Our drones support local and state law enforcement agencies with
            advanced aerial surveillance, search and rescue, crowd monitoring,
            and emergency response capabilities. By integrating AI-driven
            analytics and real-time situational awareness, we empower law
            enforcement to operate more effectively and safely.
          </p>
        </div>
      </div>

      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <h2 style={styles.highlight}>Commercial Applications: Delivery, Agriculture & Beyond</h2>
        <p style={styles.text}>
          Beyond defense, our drones serve a broad range of industries:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>
            <span style={styles.listItemBullet}></span>
            Logistics & Delivery – Expediting last-mile delivery and supply
            chain efficiencies with autonomous aerial transport.
          </li>
          <li style={styles.listItem}>
            <span style={styles.listItemBullet}></span>
            Agriculture – Enhancing precision farming with aerial crop
            monitoring, spraying, and data analytics.
          </li>
          <li style={styles.listItem}>
            <span style={styles.listItemBullet}></span>
            Infrastructure & Inspection – Streamlining asset inspections for
            energy, construction, and telecommunications.
          </li>
        </ul>
      </div>

      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <h2 style={styles.highlight}>Affiliation with Leading Pilot Training Programs</h2>
        <p style={styles.text}>
          Ensuring seamless integration and operational excellence, we
          collaborate with an industry-leading pilot training company to provide
          expert training for both defense personnel and commercial operators.
          Our training programs equip clients with the necessary skills to
          maximize the potential of our UAS technology.
        </p>
      </div>

      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <h2 style={styles.highlight}>Advancing the Future of Air Mobility</h2>
        <p style={styles.text}>
          As pioneers in the Advanced Air Mobility (AAM) sector, we are
          committed to shaping the future of autonomous aerial transportation.
          Our innovations drive the development of scalable, efficient, and safe
          aerial mobility solutions for urban and rural environments.
        </p>
      </div>

      <div style={{ textAlign: "center" }}>
        <h2 style={styles.highlight}>Your Trusted Partner in Aerial Innovation</h2>
        <p style={styles.text}>
          At Think Development Systems, we work closely with clients to develop
          customized, end-to-end drone solutions that redefine efficiency,
          security, and productivity. Whether for defense operations, commercial
          applications, or next-generation air mobility, we are your trusted
          partner in unmanned innovation.
        </p>
        <button style={styles.ctaButton} className="button-hover">
          Let’s elevate your operations. Contact us today!
        </button>
      </div>
    </div>
  );
}

export default Drone;
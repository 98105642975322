import React, { useState } from "react";
import "../Application/Dashboard.css";

import EnterpriseSoftware from "./EnterpriseSoftware";
import Overview from "./Overviews";
import MachineLearning from "./MachineLearning";
import GlobalOffshore from "./GlobalOffshore";
import StrategicCommunications from "./StrategicCommunications";
import ApplicationsComponent from "./Application";
import ApplicationsManagement from "./ApplicationsManagement";
import BusinessIntelligence from "./BusinessIntelligence";
import PortalApplications from "./PortalApplications";
import SOAArchitecture from "./SOAArchitecture";
import ContinuationReengineering from "./ContinuationReengineering";
import ContentManagement from "./ContentManagement";


const components = {
  0: <Overview/>,
  1: <EnterpriseSoftware />,
  2: <GlobalOffshore/>,
  3: <ApplicationsComponent/>,
  4: <ApplicationsManagement/>,
  5: <BusinessIntelligence/>,
  6: <PortalApplications/>,
  7: <SOAArchitecture/>,
  8: <ContinuationReengineering/>,
  9: <ContentManagement/>
};

const ApplicationDevelopment = () => {
  const [selected, setSelected] = useState(0);

  const points = [
    { title: "Overview" },
    { title: "Enterprise Software Applications"},
    { title: "Global Offshore Development Capabilities"},
    {title : "Applications Development"},
    {title : "Applications Management"},
    { title : "Business Intelligence / Business Visualization"},
    { title : "Portal Applications"},
    { title : "SOA Architecture"},
    { title : "Continuation Re-engineering and Migration"},
    { title : "Content Management Solutions"}
  ];

  return (
    <div className="container">
      <div className="app-development">
        {/* Sidebar Navigation */}
        <div className="sidebar">
          <h2 className="sidebar-title">Application Development</h2>
          <ul>
            {points.map((point, index) => (
              <li key={index}>
                <button
                  className={`sidebar-button ${selected === index ? "active" : ""}`}
                  onClick={() => setSelected(index)}
                >
                  {point.title}
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Dynamic Component Rendering */}
        <div className="content">
          {selected !== null ? components[selected] : <p className="content-placeholder">Click on an option to see content.</p>}
        </div>
      </div>
    </div>
  );
};

export default ApplicationDevelopment;

import "./Nova.css";
import demoVideo from "../../asset/nove.mp4";

const NovaComponent = () => {
  return (
    <div className="nova-container">
      <h2 className="nova-title">Revolutionizing Customer Experience with AI-Powered LLM Drive-Through & Self-Service Kiosks</h2>
      <p className="nova-text">
        The Future of Convenience & Efficiency is Here
      </p>

      <div className="nova-video-container">
  <h3 className="nova-title">SEE THINK NOVA, AI-Powered Kiosks, In Action</h3>
  <video width="100%" height="400" controls>
    <source src={demoVideo} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>


      <p className="nova-text">
        In today’s fast-paced world, speed, accuracy, and seamless service are more critical than ever. AI-powered Large Language Model (LLM) software and advanced kiosk hardware are transforming the way businesses interact with customers, redefining convenience in the fast food and travel industries. By adopting AI-driven kiosks, businesses can enhance efficiency, improve customer satisfaction, and stay ahead of evolving consumer expectations.
      </p>

      <h3 className="nova-title">WHY THINK NOVA?</h3>
      <ul className="nova-list">
        <li>Faster Order Processing: Reduce wait times and streamline service with AI-enhanced voice and touchscreen ordering.</li>
        <li>Personalized Customer Experience: AI-driven recommendations enhance upselling and create tailored meal suggestions based on customer preferences.</li>
        <li>Multilingual Support: Seamlessly serve a diverse customer base with AI-powered natural language processing.</li>
        <li>Operational Efficiency: Reduce labor costs and optimize staff allocation for higher-value tasks.</li>
        <li>24/7 Availability: Ensure continuous service without staffing limitations.</li>
      </ul>

      <h3 className="nova-title">The Impact on the Fast Food Industry</h3>
      <p className="nova-text">
        Fast food chains face growing demands for speed, accuracy, and convenience. AI-powered drive-through kiosks and self-service ordering stations address these challenges by:
      </p>
      <ul className="nova-list">
        <li>Minimizing Order Errors: AI ensures precise order capturing, reducing mistakes and improving customer satisfaction.</li>
        <li>Boosting Sales: Smart AI upselling features suggest add-ons and promotions tailored to customer behavior.</li>
        <li>Enhancing Order Throughput: AI-powered automation allows for higher order volume with reduced bottlenecks.</li>
        <li>Optimizing Staffing: Reduce reliance on front-line staff, allowing them to focus on food preparation and customer service.</li>
      </ul>

      <h3 className="nova-title">THINK NOVA IN THE TRAVEL INDUSTRY</h3>
      <p className="nova-text">
        For the travel sector, self-service kiosks are revolutionizing the passenger experience by:
      </p>
      <ul className="nova-list">
        <li>Streamlining Check-Ins: Expedite hotel, airline, and car rental check-ins with AI-driven kiosks.</li>
        <li>Reducing Wait Times: Improve service speed and minimize long queues at airports, train stations, and hotels.</li>
        <li>Enhancing Customer Engagement: Provide travel recommendations, local attractions, and itinerary updates through AI-powered interactions.</li>
        <li>Contactless Transactions: Promote hygiene and safety with touchless payment and digital assistance.</li>
      </ul>

      <h3 className="nova-title">Why Now? Future-Proof Your Business</h3>
      <p className="nova-text">
        With AI technology advancing at an unprecedented rate, businesses that adopt AI-powered kiosks today will position themselves as industry leaders tomorrow. Enhancing customer engagement, operational efficiency, and revenue growth has never been easier.
      </p>
      <p className="nova-text">
        Stay ahead of the curve—embrace the future of automation and customer service. Contact us today to learn how AI-powered kiosks can transform your business!
      </p>
    </div>
  );
};

export default NovaComponent;
import React from "react";
import "./ApplicationsComponent.css";

const ApplicationsComponent = () => {
  return (
    <div className="applications-container">
      <h2 className="applications-title">Applications Development</h2>
      <p className="applications-text">
        Since inception, we have provided resources to manage critical applications for numerous Fortune 1000 companies and Government organizations. Our portfolio includes:
      </p>

      {/* Key Services List */}
      <ul className="applications-list">
        <li>✔ Customized application design, development, and implementation</li>
        <li>✔ Systems integration and consolidation</li>
        <li>✔ Re-engineering, performance tuning, and porting services</li>
        <li>✔ Implementation of packages</li>
        <li>✔ Feasibility and requirement analysis for business cases</li>
      </ul>

      <p className="applications-text">
        We leverage our rich experience in application development and research in new technologies to undertake consulting and implementation engagements. This allows our clients to smoothly transition to Web-based solutions that address their evolving business needs.
      </p>

      <h3 className="applications-subtitle">Our Significant Offerings</h3>

      {/* Offerings Grid */}
      <div className="offerings-grid">
        <div className="offering-card">📦 Supply Chain Management Solutions</div>
        <div className="offering-card">🏥 Health Care Software Solutions</div>
        <div className="offering-card">🌐 Web Interfaces to Legacy Systems</div>
        <div className="offering-card">📢 Portal/Website/ASP Design & Implementation</div>
        <div className="offering-card">💼 B2B Exchange Design & Implementation</div>
        <div className="offering-card">📊 Data Warehousing Solutions</div>
      </div>
    </div>
  );
};

export default ApplicationsComponent;

import React from "react";
import "./AIOverview.css";

const AIDescription = () => (
  <section className="ai-description">
    <h1>Artificial Intelligence Solutions for Next-Gen Innovation</h1>
    <p className="subtitle">Revolutionizing Industries with AI-Powered Solutions</p>
    <p className="content">
      At <span className="highlight">TDS</span>, we specialize in cutting-edge AI solutions that drive innovation, 
      efficiency, and scalability. Our AI expertise includes speech recognition, AI voice assistants, NLP, 
      computer vision, and machine learning—helping businesses automate and optimize decision-making.
    </p>
  </section>
);

const AICapabilities = () => (
  <section className="ai-section">
    <h2>Our AI Capabilities</h2>
    <div className="grid-container">
      {[
        { title: "AI Voice Assistants & Speech Recognition", description: "We build real-time AI-powered voice assistants using Local LLMs and Deepgram for seamless interaction." },
        { title: "Natural Language Processing (NLP)", description: "Advanced NLP models for text understanding, chatbots, and AI-driven content generation." },
        { title: "Custom AI Model Development", description: "Expert AI model customization using transfer learning, domain adaptation, and RAFT." },
        { title: "AI Deployment & Cloud Integration", description: "Cloud-native AI optimized for AWS, Azure, and Google Cloud with Docker integration." },
      ].map((item, index) => (
        <div key={index} className="card1">
          <h3>{item.title}</h3>
          <p>{item.description}</p>
        </div>
      ))}
    </div>
  </section>
);

const IndustriesServed = () => (
  <section className="ai-section">
    <h2>Industries We Serve</h2>
    <div className="industries-container">
      {[
        { industry: "Retail & E-commerce", description: "AI chatbots, recommendation engines, and customer analytics." },
        { industry: "Healthcare", description: "AI-driven medical diagnostics, predictive analytics, and image analysis." },
        { industry: "Food & Hospitality", description: "Voice AI for kiosks and automated customer service." },
        { industry: "Education & Research", description: "AI-powered learning platforms and automated assessments." },
      ].map((item, index) => (
        <div key={index} className="industry-card">
          <h3>{item.industry}</h3>
          <p>{item.description}</p>
        </div>
      ))}
    </div>
  </section>
);

const WhyChooseTDS = () => (
  <section className="why-choose">
    <h2>Why Choose TDS?</h2>
    <ul>
      {[
        { title: "Domain-Specific AI Solutions", description: "Tailored AI models for industry-specific needs." },
        { title: "Advanced Model Optimization", description: "High-speed, low-latency AI processing." },
        { title: "Scalable AI Deployments", description: "Cloud-native AI for seamless integration." },
        { title: "Real-Time AI Applications", description: "Optimized for live data processing and automation." },
      ].map((item, index) => (
        <li key={index}>
          <span>✔</span>
          <div>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        </li>
      ))}
    </ul>
  </section>
);

const CallToAction = () => (
  <section className="cta-section1">
    <h2>Ready to transform your business with AI?</h2>
    <p>Let’s build the future together!</p>
  </section>
);

const AIOverview = () => {
  return (
    <div className="ai-overview" style={{background : "#07609F"}}>
      <AIDescription />
      <AICapabilities />
      <IndustriesServed />
      <WhyChooseTDS />
      <CallToAction />
    </div>
  );
};

export default AIOverview;

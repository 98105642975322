import React from "react";
import financialImage1 from "../../asset/FinSAC.png";
import financialImage2 from "../../asset/bank_img.jpg";
import "./FinancialSectorComponent.css";

const FinancialSectorComponent = () => {
  return (
    <div className="container">
      <div className="financial-container">
        <h2 className="financial-title">Financial Sector</h2>
        <p className="financial-text">
          TDS consulting group provides a world-class, enterprise-wide, Information
          Technology Service. As an example, customers may have legacy mainframe
          systems. TDS can provide professional services helping them devise such a
          comprehensive IT strategy. We assess the state of technology,
          infrastructure, and automation in different sectors, identify and
          prioritize key problem areas, and define opportunities for providing IT
          solutions. Most importantly, we make every effort to preserve and build
          on available strengths, protect current investments in assets, technology,
          and work practices.
        </p>
        <p className="financial-text">
          We offer a complete assistance package in adopting technologies for
          decision support, with flexibility and capability to act or react at the
          requisite speed and generate valuable analytical insights. We help
          Financial Institutions in defining Business Intelligence strategies,
          requirement analysis, development, implementation plan, and
          implementation of solutions.
        </p>
        <h3 className="financial-subtitle">Technology Delivery Models</h3>
        <div className="financial-images">
          <img
            src={financialImage1}
            alt="Financial technology solutions"
            className="financial-image"
          />
        </div>
        <p className="financial-text">
          These customer-specific, technology delivery models may include the
          following major application structures.
        </p>
        <div className="financial-images">
          <img
            src={financialImage2}
            alt="Secure financial infrastructure"
            className="financial-image"
          />
        </div>

        <h3 className="financial-subtitle">Banking</h3>
        <p className="financial-text">
          TDS combines industry solutions with deep domain knowledge and expertise to enable banks to improve services, customer satisfaction, and bottom lines. Our three-dimensional service offering model addresses customer needs holistically.
        </p>
        <p className="financial-text">
          Our competencies in Banking include application development, maintenance, and support, as well as packaged/customized application implementation and deployment. We also provide enterprise solutions through consulting, data warehousing, and business intelligence, ERP—the full range of solutions for institutions across all business channels.
        </p>

        <h4 className="financial-subtitle">Consumer Banking</h4>
        <ul className="financial-list">
          <li>Teller Applications</li>
          <li>Loans & Deposits</li>
          <li>Card Products</li>
          <li>Remittance Services (Collections and Payments)</li>
          <li>Private Banking</li>
        </ul>

        <h4 className="financial-subtitle">Corporate Banking</h4>
        <h4 className="financial-subtitle">Investment Banking / Mergers & Acquisitions</h4>
        <h4 className="financial-subtitle">Treasury</h4>
        <h4 className="financial-subtitle">Data Warehousing & CRM</h4>
        <h4 className="financial-subtitle">Capital Market</h4>
        <h4 className="financial-subtitle">Fund Management</h4>
        <h4 className="financial-subtitle">Interbank Solutions</h4>
        <h4 className="financial-subtitle">Delivery Channels</h4>

        <h3 className="financial-subtitle">TDS Migration Solution</h3>
        <p className="financial-text">
          TDS’ Migration solution is an integrated, end-to-end offering that covers capacity planning, effort estimation, actual migration, system testing, and system administration. It includes proprietary tools and scripts to improve the efficiency and productivity of the end-to-end migration process. Our solution helps you develop a clear migration strategy and roadmap to enable safe and smooth transition to the latest systems from Legacy systems.
        </p>
        <ul className="financial-list">
          <li>Pilot for migrating non-critical computation-intensive applications to the latest platform and run them in parallel to assess performance.</li>
          <li>Test before moving the migrated applications to production.</li>
          <li>Consolidate your servers and migrate critical computation-intensive applications in a planned manner.</li>
          <li>Continually adopt new systems and migrate your applications.</li>
        </ul>

        <h4 className="financial-subtitle">Migration Phases</h4>
        <ul className="financial-list">
          <li><strong>Feasibility Study:</strong> Identify candidates to migrate, evaluate risks, evaluate TCO (Total Cost of Ownership), and assess tangible ROI.</li>
          <li><strong>Planning:</strong> Define details of the tactical approach, detailed tasks, steps, resources, and timeline.</li>
          <li><strong>Execution:</strong> Implement security framework, clustering framework, and port the applications.</li>
          <li><strong>Validation:</strong> Complete run-fix cycle, integrate Thinkware Security, and finalize hardware sizing.</li>
          <li><strong>Migration:</strong> Application performance analysis, testing tools, debugging, production planning, deployment, training, and support.</li>
        </ul>

        <h3 className="financial-subtitle">Electronic Bank Records</h3>
        <p className="financial-text">
          Digitizing paper files into electronic form may seem like a daunting task, regardless of the size of your organization. The amount of time saved with a more accurate process management produces an enormous amount of cost saving. TDS can help you to achieve these cost saving methodologies with our proven process.
        </p>

        <h4 className="financial-subtitle">Field Survey</h4>
        <p className="financial-text">
          The Field survey is critical to the success of a project. During the survey, we gather information about your documents such as the security level, volume, file structure, indexing criteria, and the preparation required. Other items include color documents, oversized drawings, older faded documents, paper types, and bindings such as staples, paper clips, or binders. All of these components will factor into the cost of converting paper files into digital read-only images.
        </p>

        <h4 className="financial-subtitle">Process Enablers</h4>
        <ul className="financial-list">
          <li>Scanning</li>
          <li>Quality Control</li>
          <li>Validation</li>
          <li>Document Release</li>
        </ul>
      </div>
    </div>
  );
};

export default FinancialSectorComponent;